<template>
  <validation-observer
    ref="saveFormRef"
    #default="{invalid}"
  >
    <b-modal
      id="saveModal"
      body-class="position-static"
      centered
      :title="isCreated ? 'Thêm kế hoạch thu' : 'Cập nhật kế hoạch thu'"
      no-close-on-backdrop
      @show="onShow"
      @hide="onHide"
    >
      <b-form>

        <b-row>
          <b-col cols="12">
            <b-form-group label-for="admissionRevenueId">
              <template v-slot:label>
                Khoản thu <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Khoản thu"
                rules="required"
              >
                <v-select
                  v-model="targetItem.admissionRevenueId"
                  :options="admissionRevenues"
                  :reduce="option => option.value"
                  @input="getAdmissionForm"
                />
                <b-form-invalid-feedback :state="getElementState(errors)">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <b-form-group label-for="admissionFormId">
              <template v-slot:label>
                Phương thức tuyển sinh <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Phương thức tuyển sinh"
                rules="required"
              >
                <v-select
                  v-model="targetItem.admissionFormId"
                  :options="dataListAdmissionForm"
                  :reduce="option => option.value"
                />
                <b-form-invalid-feedback :state="getElementState(errors)">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="6">
            <b-form-group
              label="Ngày bắt đầu"
              label-for="startAt"
            >
              <flat-pickr
                v-model="targetItem.startAt"
                class="form-control"
                :config="config"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              label="Ngày kết thúc"
              label-for="endAt"
            >
              <flat-pickr
                v-model="targetItem.endAt"
                class="form-control"
                :config="config"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <b-form-group label-for="check">
              <b-form-radio-group
                id="radio-group-2"
                v-model="check"
                name="radio-sub-component"
                @change="handleChangeType"
              >
                <b-form-radio value="1">Theo nguyện vọng</b-form-radio>
                <b-form-radio value="2">Theo môn</b-form-radio>
                <b-form-radio value="3">Theo phúc khảo</b-form-radio>
              </b-form-radio-group>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" v-if="check == 1">
            <b-form-group label-for="type">
              <template v-slot:label>
                Khoản thu phụ thuộc vào <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Khoản thu phụ thuộc vào"
                rules="required"
              >
                <v-select
                  v-model="targetItem.type"
                  :options="typeOptions"
                  :reduce="option => option.value"
                />
                <b-form-invalid-feedback :state="getElementState(errors)">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" v-if="check == 2">
            <b-form-group label-for="admissionSubjectId">
              <template v-slot:label>
                Thu theo môn thi
              </template>
              <v-select
                :disabled="targetItem.type === 1"
                v-model="targetItem.admissionSubjectId"
                :options="dataListSubject"
                :reduce="option => option.value"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="6">
            <b-form-group label-for="price">
              <template v-slot:label>
                Số tiền <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Số tiền khoản thu"
                rules="required"
              >
                <b-form-input
                  id="total"
                  v-model="targetItem.price"
                  type="number"
                  min="0"
                  name="total"
                  placeholder="Nhập số tiền"
                  :state="getElementState(errors)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group label-for="status">
              <template v-slot:label>
                Trạng thái <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Trạng thái"
                rules="required"
              >
                <v-select
                  v-model="targetItem.status"
                  :options="statusOptions"
                  :reduce="option => option.value"
                />
                <b-form-invalid-feedback :state="getElementState(errors)">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
      <template #modal-footer>
        <div class="w-100 d-flex justify-content-end">
          <b-button
            v-show="isCreated"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            :disabled="invalid"
            @click="onSave"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Lưu và tiếp tục
            </span>
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            :disabled="invalid"
            @click="onSave('hide')"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Lưu lại
            </span>
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            @click="$bvModal.hide('saveModal')"
          >
            <span class="text-right">
              <feather-icon icon="XIcon" /> Hủy
            </span>
          </b-button>
        </div>
      </template>
      <b-overlay
        no-wrap
        variant="white"
        spinner-variant="primary"
        blur="0"
        opacity=".75"
        rounded="sm"
        :show="isLoading"
      />
    </b-modal>
  </validation-observer>
</template>

<script>
import {
  BButton, BCol, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BModal, BOverlay, BRow, BFormRadioGroup, BFormRadio
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import vSelect from 'vue-select'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import { getUser } from '@/auth/utils'
import flatPickr from 'vue-flatpickr-component'
import { vn } from 'flatpickr/dist/l10n/vn.js'

export default {
  name: 'Save',
  components: {
    flatPickr,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BModal,
    BForm,
    BOverlay,
    BFormInvalidFeedback,
    BButton,
    BFormRadioGroup, BFormRadio,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    item: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      user: getUser(),
      isLoading: false,
      targetItem: {
        admissionRevenueId: null,
        admissionSubjectId: null,
        startAt: null,
        endAt: null,
        price: null,
        type: 0,
        status: 1,
        orderNo: 1,
      },
      config: {
        wrap: true,
        enableTime: false,
        altInput: true,
        altFormat: 'Y-m-d',
        dateFormat: 'Y-m-d',
        locale: vn,
      },
      required,
      check: 1
    }
  },
  computed: {
    ...mapGetters({
      statuses: 'admissionRound/statuses',
      admissionRevenues: 'admissionRevenue/dataAdmissionRevenues',
      typeAdmissionRevenuePlans: 'admissionRevenueType/typeAdmissionRevenuePlans',
      dataListSubject: 'factAdmissionSubject/dataListSubject',
      dataListAdmissionForm: 'admissionForm/admissionFormByAdmissionRoundId',
    }),
    statusOptions() {
      return this.statuses
    },
    typeOptions() {
      return this.typeAdmissionRevenuePlans
    },
    isCreated() {
      return !this.item
    },
  },
  methods: {
    ...mapActions({
      createAdmissionRevenuePlans: 'admissionRevenuePlan/createAdmissionRevenuePlans',
      updateAdmissionRevenuePlans: 'admissionRevenuePlan/updateAdmissionRevenuePlans',
      getAdmissionRevenueTypes: 'admissionRevenueType/getAdmissionRevenueTypes',
      getDataAdmissionRevenues: 'admissionRevenue/getDataAdmissionRevenues',
      getListSubject: 'factAdmissionSubject/getListSubject',
      readAdmissionFormByAdmissionRoundId: 'admissionForm/readAdmissionFormByAdmissionRoundId',
      readAdmissionRevenueById: 'admissionRevenue/readAdmissionRevenueById'
    }),
    getElementState(errors) {
      return errors.length > 0 ? false : null
    },
    async onShow() {
      await this.getDataAdmissionRevenues()
      await this.getAdmissionRevenueTypes()
      await this.getListSubject()
      if ( this.item ) {
        this.targetItem = { ...this.item }
        if (this.targetItem.type === 1 || (this.targetItem.type === 0 && this.targetItem.admissionSubjectId === null)) {
          this.check = '1'
        }
        if(this.targetItem.admissionSubjectId) {
          this.check = '2'
        }
        if (this.targetItem.type === null) {
          this.check = '3'
        }
        await this.getAdmissionForm()
      }
    },
    handleChangeType(type){
      if(type == '1') {
        this.targetItem.admissionSubjectId = null
      } else {
        this.targetItem.type = 0
      }

    },
    async getAdmissionForm() {
     const res =  await this.readAdmissionRevenueById({id : this.targetItem.admissionRevenueId})
      if (res && res.admissionRoundId) {
        await this.readAdmissionFormByAdmissionRoundId({ admissionRoundId: res.admissionRoundId })
      }

    },
    onHide() {
      this.$refs
        .saveFormRef
        .reset()
      this.targetItem = {
        admissionRevenueId: null,
        admissionSubjectId: null,
        startAt: null,
        endAt: null,
        price: null,
        type: 0,
        status: 1,
        orderNo: 1
      }
    },
    async onSave(type = null) {
      const valid = this.$refs
        .saveFormRef
        .validate()
      if ( valid ) {
        this.isLoading = true
        if (this.check === '3') {
          this.targetItem.type = null
        }
        try {
          const response = this.isCreated
            ? await this.createAdmissionRevenuePlans(this.targetItem)
            : await this.updateAdmissionRevenuePlans(this.targetItem)
          if (response) {
            const {
              isSuccessful,
              message
            } = response
            if (isSuccessful) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })
              if (type === 'hide') {
                this.$bvModal.hide('saveModal')
              }
              this.$emit('succeed')
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'XCircleIcon',
                  variant: 'danger',
                },
              })
            }
          }
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `[${e.code}] ${e.message}`,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        } finally {
          this.isLoading = false
        }
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
